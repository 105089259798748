.bjs-container {
  background-color: white !important;
}

.djs-container {
  background-color: white !important;
}

.djs-lane {
  fill: white !important;
  stroke: #333333 !important;
  stroke-width: 1px !important;
}

.djs-lane-label {
  color: #333333 !important;
  font-family: Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.djs-collaboration {
  stroke: #333333 !important;
}

.djs-shape {
  shape-rendering: geometricPrecision;
}

.bjs-powered-by {
  display: none !important;
}

/* Container styles */
.bpmn-diagram-container {
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
}

/* Pool and lane container */
.djs-collaboration,
.djs-pool,
.djs-participant,
.djs-lane {
  fill: white !important;
  stroke: #333333 !important;
}

/* Lane label styling */
.djs-label[data-element-id$="_label"] {
  font-weight: bold !important;
  fill: #333333 !important;
}

/* Improve task appearance */
.djs-visual[data-element-id^="Task_"] rect {
  fill: white !important;
  stroke: #333333 !important;
  stroke-width: 1.5px !important;
}

.djs-palette {
  display: none !important;
}

.djs-context-pad {
  display: none !important;
}

.bjs-powered-by {
  display: none !important;
}

.djs-container {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}